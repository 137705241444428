/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Artist,
  CampaignRevenueEntry,
  CampaignStats,
  Docs,
  FullNews,
  GetArtistsLableId200,
  GetBalanceResponse,
  GetFavoritesNews200,
  GetFeatured200,
  GetInvestors200,
  GetKYCV1Status200,
  GetKYCV1SumSubToken200,
  GetNews200,
  GetPaymentsPaypal200,
  GetPaymentsV1PaypalError,
  GetProductNews200,
  GetProducts200,
  GetUserFavorites200,
  GetUserNicknameValidate200,
  GetUserPortfolio200,
  GetUserProfile200,
  GetUserTransactions200,
  GetUsersV1ProfileError,
  InternalServerError500,
  PostArtistsV1Error,
  PostAvatar200,
  PostCrowdfundingV1PurchaseRequest,
  PostDeleteUserFavoriteRequest,
  PostFeeResponse,
  PostFilesDocs200,
  PostFilesV1CommonError,
  PostFilesV1DocsError,
  PostNewsV1UserFavoritesError,
  PostPaymentsPaypal200,
  PostPaymentsPaypalRequest,
  PostPaymentsV1DepositRequest,
  PostPaymentsV1DepositResponse,
  PostPaymentsV1FeeError,
  PostPaymentsV1FeeRequest,
  PostProductsV1Error,
  PostPutArtistsRequest,
  PostPutProductsRequest,
  PostUserPhone200,
  PostUserPhoneRequest,
  PostUserPhoneValidate200,
  PostUserPhoneValidateRequest,
  PostUsersV1AvatarError,
  PostUsersV1PhoneError,
  PostUsersV1PhoneValidateError,
  PostWalletV1WithdrawalError,
  PostWithdrawalRequest,
  PostWithdrawalResponse,
  Product,
  PutArtistsV1ArtistIdError,
  PutProductsV1ProductIdError,
  PutUserProfileResponse200,
  PutUsersV1ProfileError,
  PutUsersV1ProfilePrivacyRequest,
  UpdateProfileRequest,
  UserInfo,
} from '../models/index';
import {
    ArtistFromJSON,
    ArtistToJSON,
    CampaignRevenueEntryFromJSON,
    CampaignRevenueEntryToJSON,
    CampaignStatsFromJSON,
    CampaignStatsToJSON,
    DocsFromJSON,
    DocsToJSON,
    FullNewsFromJSON,
    FullNewsToJSON,
    GetArtistsLableId200FromJSON,
    GetArtistsLableId200ToJSON,
    GetBalanceResponseFromJSON,
    GetBalanceResponseToJSON,
    GetFavoritesNews200FromJSON,
    GetFavoritesNews200ToJSON,
    GetFeatured200FromJSON,
    GetFeatured200ToJSON,
    GetInvestors200FromJSON,
    GetInvestors200ToJSON,
    GetKYCV1Status200FromJSON,
    GetKYCV1Status200ToJSON,
    GetKYCV1SumSubToken200FromJSON,
    GetKYCV1SumSubToken200ToJSON,
    GetNews200FromJSON,
    GetNews200ToJSON,
    GetPaymentsPaypal200FromJSON,
    GetPaymentsPaypal200ToJSON,
    GetPaymentsV1PaypalErrorFromJSON,
    GetPaymentsV1PaypalErrorToJSON,
    GetProductNews200FromJSON,
    GetProductNews200ToJSON,
    GetProducts200FromJSON,
    GetProducts200ToJSON,
    GetUserFavorites200FromJSON,
    GetUserFavorites200ToJSON,
    GetUserNicknameValidate200FromJSON,
    GetUserNicknameValidate200ToJSON,
    GetUserPortfolio200FromJSON,
    GetUserPortfolio200ToJSON,
    GetUserProfile200FromJSON,
    GetUserProfile200ToJSON,
    GetUserTransactions200FromJSON,
    GetUserTransactions200ToJSON,
    GetUsersV1ProfileErrorFromJSON,
    GetUsersV1ProfileErrorToJSON,
    InternalServerError500FromJSON,
    InternalServerError500ToJSON,
    PostArtistsV1ErrorFromJSON,
    PostArtistsV1ErrorToJSON,
    PostAvatar200FromJSON,
    PostAvatar200ToJSON,
    PostCrowdfundingV1PurchaseRequestFromJSON,
    PostCrowdfundingV1PurchaseRequestToJSON,
    PostDeleteUserFavoriteRequestFromJSON,
    PostDeleteUserFavoriteRequestToJSON,
    PostFeeResponseFromJSON,
    PostFeeResponseToJSON,
    PostFilesDocs200FromJSON,
    PostFilesDocs200ToJSON,
    PostFilesV1CommonErrorFromJSON,
    PostFilesV1CommonErrorToJSON,
    PostFilesV1DocsErrorFromJSON,
    PostFilesV1DocsErrorToJSON,
    PostNewsV1UserFavoritesErrorFromJSON,
    PostNewsV1UserFavoritesErrorToJSON,
    PostPaymentsPaypal200FromJSON,
    PostPaymentsPaypal200ToJSON,
    PostPaymentsPaypalRequestFromJSON,
    PostPaymentsPaypalRequestToJSON,
    PostPaymentsV1DepositRequestFromJSON,
    PostPaymentsV1DepositRequestToJSON,
    PostPaymentsV1DepositResponseFromJSON,
    PostPaymentsV1DepositResponseToJSON,
    PostPaymentsV1FeeErrorFromJSON,
    PostPaymentsV1FeeErrorToJSON,
    PostPaymentsV1FeeRequestFromJSON,
    PostPaymentsV1FeeRequestToJSON,
    PostProductsV1ErrorFromJSON,
    PostProductsV1ErrorToJSON,
    PostPutArtistsRequestFromJSON,
    PostPutArtistsRequestToJSON,
    PostPutProductsRequestFromJSON,
    PostPutProductsRequestToJSON,
    PostUserPhone200FromJSON,
    PostUserPhone200ToJSON,
    PostUserPhoneRequestFromJSON,
    PostUserPhoneRequestToJSON,
    PostUserPhoneValidate200FromJSON,
    PostUserPhoneValidate200ToJSON,
    PostUserPhoneValidateRequestFromJSON,
    PostUserPhoneValidateRequestToJSON,
    PostUsersV1AvatarErrorFromJSON,
    PostUsersV1AvatarErrorToJSON,
    PostUsersV1PhoneErrorFromJSON,
    PostUsersV1PhoneErrorToJSON,
    PostUsersV1PhoneValidateErrorFromJSON,
    PostUsersV1PhoneValidateErrorToJSON,
    PostWalletV1WithdrawalErrorFromJSON,
    PostWalletV1WithdrawalErrorToJSON,
    PostWithdrawalRequestFromJSON,
    PostWithdrawalRequestToJSON,
    PostWithdrawalResponseFromJSON,
    PostWithdrawalResponseToJSON,
    ProductFromJSON,
    ProductToJSON,
    PutArtistsV1ArtistIdErrorFromJSON,
    PutArtistsV1ArtistIdErrorToJSON,
    PutProductsV1ProductIdErrorFromJSON,
    PutProductsV1ProductIdErrorToJSON,
    PutUserProfileResponse200FromJSON,
    PutUserProfileResponse200ToJSON,
    PutUsersV1ProfileErrorFromJSON,
    PutUsersV1ProfileErrorToJSON,
    PutUsersV1ProfilePrivacyRequestFromJSON,
    PutUsersV1ProfilePrivacyRequestToJSON,
    UpdateProfileRequestFromJSON,
    UpdateProfileRequestToJSON,
    UserInfoFromJSON,
    UserInfoToJSON,
} from '../models/index';

export interface DeleteArtistsV1ArtistIdRequest {
    artistId: string;
}

export interface DeleteNewsV1UserFavoritesRequest {
    productId?: string;
}

export interface DeleteProductsV1Request {
    productId?: string;
}

export interface GetArtistsV1Request {
    limit: number;
    offset: number;
}

export interface GetArtistsV1ArtistIdRequest {
    artistId: string;
}

export interface GetCrowdfundingV1CampaignRevenuesRequest {
    campaignId: string;
    monthCount: string;
}

export interface GetCrowdfundingV1CampaignStatsRequest {
    campaignId: string;
    monthCount: string;
}

export interface GetFilesV1DocsRequest {
    productId: string;
}

export interface GetInvestorsV1ProductIdRequest {
    productId: string;
    limit: number;
    offset: number;
}

export interface GetNewsRequest {
    limit: number;
    offset: number;
}

export interface GetNewsNewsIdRequest {
    newsId: string;
}

export interface GetNewsV1ProductProductIdRequest {
    productId: string;
}

export interface GetNewsV1UserFavoritesUserIdRequest {
    userId: string;
}

export interface GetNewsV1UserNewsUserIdRequest {
    userId: string;
    limit?: number;
    offset?: number;
}

export interface GetPortfolioV1UserPortfoliosUserIdRequest {
    userId: string;
    limit: number;
    offset: number;
}

export interface GetProductV1ArtistProductsRequest {
    artistId: string;
    limit: number;
    offset: number;
}

export interface GetProductsV1Request {
    limit: number;
    offset: number;
}

export interface GetProductsV1AllRequest {
    limit: number;
    offset: number;
}

export interface GetProductsV1ProductIdRequest {
    productId: string;
}

export interface GetUsersV1NicknameValidateRequest {
    nickname: string;
}

export interface GetUsersV1UserIdInfoRequest {
    userId: string;
}

export interface GetWalletV1TransactionsRequest {
    limit: number;
    offset: number;
    transactionType?: GetWalletV1TransactionsTransactionTypeEnum;
}

export interface PostArtistsV1Request {
    postPutArtistsRequest?: PostPutArtistsRequest;
}

export interface PostCrowdfundingV1PurchaseOperationRequest {
    postCrowdfundingV1PurchaseRequest?: PostCrowdfundingV1PurchaseRequest;
}

export interface PostFilesV1CommonRequest {
    mimeType: PostFilesV1CommonMimeTypeEnum;
    file: Blob;
}

export interface PostFilesV1DocsRequest {
    file: Blob;
}

export interface PostNewsV1UserFavoritesRequest {
    postDeleteUserFavoriteRequest?: PostDeleteUserFavoriteRequest;
}

export interface PostPaymentsV1DepositOperationRequest {
    postPaymentsV1DepositRequest?: PostPaymentsV1DepositRequest;
}

export interface PostPaymentsV1FeeOperationRequest {
    postPaymentsV1FeeRequest?: PostPaymentsV1FeeRequest;
}

export interface PostPaymentsV1PaypalRequest {
    postPaymentsPaypalRequest?: PostPaymentsPaypalRequest;
}

export interface PostProductsV1Request {
    postPutProductsRequest?: PostPutProductsRequest;
}

export interface PostUsersV1AvatarRequest {
    image: Blob;
}

export interface PostUsersV1PhoneRequest {
    postUserPhoneRequest?: PostUserPhoneRequest;
}

export interface PostUsersV1PhoneValidateRequest {
    postUserPhoneValidateRequest?: PostUserPhoneValidateRequest;
}

export interface PostWalletV1WithdrawalRequest {
    postWithdrawalRequest?: PostWithdrawalRequest;
}

export interface PutArtistsV1ArtistIdRequest {
    artistId: string;
    postPutArtistsRequest?: PostPutArtistsRequest;
}

export interface PutProductsV1ProductIdRequest {
    productId: string;
    postPutProductsRequest?: PostPutProductsRequest;
}

export interface PutUsersV1ProfileRequest {
    updateProfileRequest?: UpdateProfileRequest;
}

export interface PutUsersV1ProfilePrivacyOperationRequest {
    putUsersV1ProfilePrivacyRequest?: PutUsersV1ProfilePrivacyRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * удаление артиста
     * /artists/v1/{artistId}
     */
    async deleteArtistsV1ArtistIdRaw(requestParameters: DeleteArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['artistId'] == null) {
            throw new runtime.RequiredError(
                'artistId',
                'Required parameter "artistId" was null or undefined when calling deleteArtistsV1ArtistId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1/{artistId}`.replace(`{${"artistId"}}`, encodeURIComponent(String(requestParameters['artistId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * удаление артиста
     * /artists/v1/{artistId}
     */
    async deleteArtistsV1ArtistId(requestParameters: DeleteArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteArtistsV1ArtistIdRaw(requestParameters, initOverrides);
    }

    /**
     * Исключение продукта из избранного пользователя
     * /news/v1/userFavorites
     */
    async deleteNewsV1UserFavoritesRaw(requestParameters: DeleteNewsV1UserFavoritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/userFavorites`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Исключение продукта из избранного пользователя
     * /news/v1/userFavorites
     */
    async deleteNewsV1UserFavorites(requestParameters: DeleteNewsV1UserFavoritesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNewsV1UserFavoritesRaw(requestParameters, initOverrides);
    }

    /**
     * /payments/v1/paypal
     */
    async deletePaymentsV1PaypalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/paypal`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * /payments/v1/paypal
     */
    async deletePaymentsV1Paypal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePaymentsV1PaypalRaw(initOverrides);
    }

    /**
     * Удаление продукта, не прошедшего модерацию.  Может быть вызван лейблом после возвращения продукта с модерации для правок.
     * /products/v1
     */
    async deleteProductsV1Raw(requestParameters: DeleteProductsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Удаление продукта, не прошедшего модерацию.  Может быть вызван лейблом после возвращения продукта с модерации для правок.
     * /products/v1
     */
    async deleteProductsV1(requestParameters: DeleteProductsV1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteProductsV1Raw(requestParameters, initOverrides);
    }

    /**
     * /users/v1/avatar
     */
    async deleteUsersV1AvatarRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/avatar`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * /users/v1/avatar
     */
    async deleteUsersV1Avatar(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUsersV1AvatarRaw(initOverrides);
    }

    /**
     * Получение артистов текущего лейбла
     * /artists/v1
     */
    async getArtistsV1Raw(requestParameters: GetArtistsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetArtistsLableId200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getArtistsV1().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getArtistsV1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetArtistsLableId200FromJSON(jsonValue));
    }

    /**
     * Получение артистов текущего лейбла
     * /artists/v1
     */
    async getArtistsV1(requestParameters: GetArtistsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetArtistsLableId200> {
        const response = await this.getArtistsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение сущности артиста по ID
     * /artists/v1/{artistId}
     */
    async getArtistsV1ArtistIdRaw(requestParameters: GetArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artist>> {
        if (requestParameters['artistId'] == null) {
            throw new runtime.RequiredError(
                'artistId',
                'Required parameter "artistId" was null or undefined when calling getArtistsV1ArtistId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1/{artistId}`.replace(`{${"artistId"}}`, encodeURIComponent(String(requestParameters['artistId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtistFromJSON(jsonValue));
    }

    /**
     * Получение сущности артиста по ID
     * /artists/v1/{artistId}
     */
    async getArtistsV1ArtistId(requestParameters: GetArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artist> {
        const response = await this.getArtistsV1ArtistIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * прибыль по кампании
     * /crowdfunding/v1/campaign/{campaignId}/revenues
     */
    async getCrowdfundingV1CampaignRevenuesRaw(requestParameters: GetCrowdfundingV1CampaignRevenuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CampaignRevenueEntry>>> {
        if (requestParameters['campaignId'] == null) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter "campaignId" was null or undefined when calling getCrowdfundingV1CampaignRevenues().'
            );
        }

        if (requestParameters['monthCount'] == null) {
            throw new runtime.RequiredError(
                'monthCount',
                'Required parameter "monthCount" was null or undefined when calling getCrowdfundingV1CampaignRevenues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['monthCount'] != null) {
            queryParameters['monthCount'] = requestParameters['monthCount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/crowdfunding/v1/campaign/{campaignId}/revenues`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters['campaignId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CampaignRevenueEntryFromJSON));
    }

    /**
     * прибыль по кампании
     * /crowdfunding/v1/campaign/{campaignId}/revenues
     */
    async getCrowdfundingV1CampaignRevenues(requestParameters: GetCrowdfundingV1CampaignRevenuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CampaignRevenueEntry>> {
        const response = await this.getCrowdfundingV1CampaignRevenuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * статистика прослушивания по кампании за период
     * /crowdfunding/v1/campaign/{campaignId}/stats
     */
    async getCrowdfundingV1CampaignStatsRaw(requestParameters: GetCrowdfundingV1CampaignStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CampaignStats>> {
        if (requestParameters['campaignId'] == null) {
            throw new runtime.RequiredError(
                'campaignId',
                'Required parameter "campaignId" was null or undefined when calling getCrowdfundingV1CampaignStats().'
            );
        }

        if (requestParameters['monthCount'] == null) {
            throw new runtime.RequiredError(
                'monthCount',
                'Required parameter "monthCount" was null or undefined when calling getCrowdfundingV1CampaignStats().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['monthCount'] != null) {
            queryParameters['monthCount'] = requestParameters['monthCount'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/crowdfunding/v1/campaign/{campaignId}/stats`.replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters['campaignId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignStatsFromJSON(jsonValue));
    }

    /**
     * статистика прослушивания по кампании за период
     * /crowdfunding/v1/campaign/{campaignId}/stats
     */
    async getCrowdfundingV1CampaignStats(requestParameters: GetCrowdfundingV1CampaignStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CampaignStats> {
        const response = await this.getCrowdfundingV1CampaignStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение набора документов из приватного бакета по ID продукта.  Метод необходим для редактирования продукта. 
     * /products/v1/docs
     */
    async getFilesV1DocsRaw(requestParameters: GetFilesV1DocsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Docs>>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getFilesV1Docs().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/docs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocsFromJSON));
    }

    /**
     * Получение набора документов из приватного бакета по ID продукта.  Метод необходим для редактирования продукта. 
     * /products/v1/docs
     */
    async getFilesV1Docs(requestParameters: GetFilesV1DocsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Docs>> {
        const response = await this.getFilesV1DocsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение рейтинга инвесторов по ID продукта
     * /investors/v1/{productId}
     */
    async getInvestorsV1ProductIdRaw(requestParameters: GetInvestorsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInvestors200>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getInvestorsV1ProductId().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getInvestorsV1ProductId().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getInvestorsV1ProductId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/investors/v1/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInvestors200FromJSON(jsonValue));
    }

    /**
     * Получение рейтинга инвесторов по ID продукта
     * /investors/v1/{productId}
     */
    async getInvestorsV1ProductId(requestParameters: GetInvestorsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInvestors200> {
        const response = await this.getInvestorsV1ProductIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Метод возвращает статус проверки KYC пользователя
     * /kyc/v1/status
     */
    async getKycV1StatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetKYCV1Status200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kyc/v1/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKYCV1Status200FromJSON(jsonValue));
    }

    /**
     * Метод возвращает статус проверки KYC пользователя
     * /kyc/v1/status
     */
    async getKycV1Status(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetKYCV1Status200> {
        const response = await this.getKycV1StatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Метод возвращает токен SumSub для WebSDK, а также статус проверки KYC пользователя
     * /kyc/v1/sumsubToken
     */
    async getKycV1SumsubTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetKYCV1SumSubToken200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/kyc/v1/sumsubToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetKYCV1SumSubToken200FromJSON(jsonValue));
    }

    /**
     * Метод возвращает токен SumSub для WebSDK, а также статус проверки KYC пользователя
     * /kyc/v1/sumsubToken
     */
    async getKycV1SumsubToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetKYCV1SumSubToken200> {
        const response = await this.getKycV1SumsubTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Метод возвращает список новостей, фильтруя их по publishDate <= текущей
     * /news/v1
     */
    async getNewsRaw(requestParameters: GetNewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNews200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getNews().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getNews().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNews200FromJSON(jsonValue));
    }

    /**
     * Метод возвращает список новостей, фильтруя их по publishDate <= текущей
     * /news/v1
     */
    async getNews(requestParameters: GetNewsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNews200> {
        const response = await this.getNewsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение новости по ID
     * /news/v1/{newsId}
     */
    async getNewsNewsIdRaw(requestParameters: GetNewsNewsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FullNews>> {
        if (requestParameters['newsId'] == null) {
            throw new runtime.RequiredError(
                'newsId',
                'Required parameter "newsId" was null or undefined when calling getNewsNewsId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/{newsId}`.replace(`{${"newsId"}}`, encodeURIComponent(String(requestParameters['newsId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FullNewsFromJSON(jsonValue));
    }

    /**
     * Получение новости по ID
     * /news/v1/{newsId}
     */
    async getNewsNewsId(requestParameters: GetNewsNewsIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FullNews> {
        const response = await this.getNewsNewsIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение новостей по избранному текущего пользователя
     * /news/v1/favorites
     */
    async getNewsV1FavoritesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFavoritesNews200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/favorites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFavoritesNews200FromJSON(jsonValue));
    }

    /**
     * Получение новостей по избранному текущего пользователя
     * /news/v1/favorites
     */
    async getNewsV1Favorites(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFavoritesNews200> {
        const response = await this.getNewsV1FavoritesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Получение списка апдейтов по конкретному продукту
     * /news/v1/product/{productId}
     */
    async getNewsV1ProductProductIdRaw(requestParameters: GetNewsV1ProductProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProductNews200>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getNewsV1ProductProductId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProductNews200FromJSON(jsonValue));
    }

    /**
     * Получение списка апдейтов по конкретному продукту
     * /news/v1/product/{productId}
     */
    async getNewsV1ProductProductId(requestParameters: GetNewsV1ProductProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProductNews200> {
        const response = await this.getNewsV1ProductProductIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение новостей по избранным продуктам пользователя
     * /news/v1/userFavorites/{userId}
     */
    async getNewsV1UserFavoritesUserIdRaw(requestParameters: GetNewsV1UserFavoritesUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserFavorites200>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getNewsV1UserFavoritesUserId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/userFavorites/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserFavorites200FromJSON(jsonValue));
    }

    /**
     * Получение новостей по избранным продуктам пользователя
     * /news/v1/userFavorites/{userId}
     */
    async getNewsV1UserFavoritesUserId(requestParameters: GetNewsV1UserFavoritesUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserFavorites200> {
        const response = await this.getNewsV1UserFavoritesUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение новостей по всем продуктам пользователя (купленные продукты, продукты в избранном)
     * /news/v1/userNews/{userId}
     */
    async getNewsV1UserNewsUserIdRaw(requestParameters: GetNewsV1UserNewsUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetNews200>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getNewsV1UserNewsUserId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/userNews/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetNews200FromJSON(jsonValue));
    }

    /**
     * Получение новостей по всем продуктам пользователя (купленные продукты, продукты в избранном)
     * /news/v1/userNews/{userId}
     */
    async getNewsV1UserNewsUserId(requestParameters: GetNewsV1UserNewsUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetNews200> {
        const response = await this.getNewsV1UserNewsUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /payments/v1/paypal
     */
    async getPaymentsV1PaypalRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetPaymentsPaypal200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/paypal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetPaymentsPaypal200FromJSON(jsonValue));
    }

    /**
     * /payments/v1/paypal
     */
    async getPaymentsV1Paypal(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetPaymentsPaypal200> {
        const response = await this.getPaymentsV1PaypalRaw(initOverrides);
        return await response.value();
    }

    /**
     * /portfolio/v1/userPortfolios/{userId}
     */
    async getPortfolioV1UserPortfoliosUserIdRaw(requestParameters: GetPortfolioV1UserPortfoliosUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserPortfolio200>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getPortfolioV1UserPortfoliosUserId().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getPortfolioV1UserPortfoliosUserId().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getPortfolioV1UserPortfoliosUserId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/portfolio/v1/userPortfolios/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserPortfolio200FromJSON(jsonValue));
    }

    /**
     * /portfolio/v1/userPortfolios/{userId}
     */
    async getPortfolioV1UserPortfoliosUserId(requestParameters: GetPortfolioV1UserPortfoliosUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserPortfolio200> {
        const response = await this.getPortfolioV1UserPortfoliosUserIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Метод для получения продуктов артиста.  Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     * /products/v1/artistProducts/{artistId}
     */
    async getProductV1ArtistProductsRaw(requestParameters: GetProductV1ArtistProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducts200>> {
        if (requestParameters['artistId'] == null) {
            throw new runtime.RequiredError(
                'artistId',
                'Required parameter "artistId" was null or undefined when calling getProductV1ArtistProducts().'
            );
        }

        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getProductV1ArtistProducts().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getProductV1ArtistProducts().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/artistProducts/{artistId}`.replace(`{${"artistId"}}`, encodeURIComponent(String(requestParameters['artistId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducts200FromJSON(jsonValue));
    }

    /**
     * Метод для получения продуктов артиста.  Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     * /products/v1/artistProducts/{artistId}
     */
    async getProductV1ArtistProducts(requestParameters: GetProductV1ArtistProductsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducts200> {
        const response = await this.getProductV1ArtistProductsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Метод для получения рекомендованных продуктов для главной страницы.  Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     * /products/v1/featured
     */
    async getProductV1FeaturedRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFeatured200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/featured`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFeatured200FromJSON(jsonValue));
    }

    /**
     * Метод для получения рекомендованных продуктов для главной страницы.  Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     * /products/v1/featured
     */
    async getProductV1Featured(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFeatured200> {
        const response = await this.getProductV1FeaturedRaw(initOverrides);
        return await response.value();
    }

    /**
     * список продуктов лейбла
     * /products/v1
     */
    async getProductsV1Raw(requestParameters: GetProductsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducts200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getProductsV1().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getProductsV1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducts200FromJSON(jsonValue));
    }

    /**
     * список продуктов лейбла
     * /products/v1
     */
    async getProductsV1(requestParameters: GetProductsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducts200> {
        const response = await this.getProductsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Получение всех продуктов отсортированных по publishDate (от новых к старым) для главной страницы. Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     *   /products/v1/all
     */
    async getProductsV1AllRaw(requestParameters: GetProductsV1AllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetProducts200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getProductsV1All().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getProductsV1All().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetProducts200FromJSON(jsonValue));
    }

    /**
     * Получение всех продуктов отсортированных по publishDate (от новых к старым) для главной страницы. Должен возвращать только продукты с publishDate <= текущая дата и статусом approved
     *   /products/v1/all
     */
    async getProductsV1All(requestParameters: GetProductsV1AllRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetProducts200> {
        const response = await this.getProductsV1AllRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * получение продукта по id (общедоступный метод)
     * /products/v1/{productId}
     */
    async getProductsV1ProductIdRaw(requestParameters: GetProductsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getProductsV1ProductId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * получение продукта по id (общедоступный метод)
     * /products/v1/{productId}
     */
    async getProductsV1ProductId(requestParameters: GetProductsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.getProductsV1ProductIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/nickname/validate
     */
    async getUsersV1NicknameValidateRaw(requestParameters: GetUsersV1NicknameValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserNicknameValidate200>> {
        if (requestParameters['nickname'] == null) {
            throw new runtime.RequiredError(
                'nickname',
                'Required parameter "nickname" was null or undefined when calling getUsersV1NicknameValidate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['nickname'] != null) {
            queryParameters['nickname'] = requestParameters['nickname'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/nickname/validate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserNicknameValidate200FromJSON(jsonValue));
    }

    /**
     * /users/v1/nickname/validate
     */
    async getUsersV1NicknameValidate(requestParameters: GetUsersV1NicknameValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserNicknameValidate200> {
        const response = await this.getUsersV1NicknameValidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get profile
     * /users/v1/profile
     */
    async getUsersV1ProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserProfile200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserProfile200FromJSON(jsonValue));
    }

    /**
     * Get profile
     * /users/v1/profile
     */
    async getUsersV1Profile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserProfile200> {
        const response = await this.getUsersV1ProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/{userId}/info
     */
    async getUsersV1UserIdInfoRaw(requestParameters: GetUsersV1UserIdInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfo>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUsersV1UserIdInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/{userId}/info`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoFromJSON(jsonValue));
    }

    /**
     * /users/v1/{userId}/info
     */
    async getUsersV1UserIdInfo(requestParameters: GetUsersV1UserIdInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfo> {
        const response = await this.getUsersV1UserIdInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /wallet/v1/balance
     */
    async getWalletV1BalanceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBalanceResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wallet/v1/balance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBalanceResponseFromJSON(jsonValue));
    }

    /**
     * /wallet/v1/balance
     */
    async getWalletV1Balance(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBalanceResponse> {
        const response = await this.getWalletV1BalanceRaw(initOverrides);
        return await response.value();
    }

    /**
     * /wallet/v1/transactions
     */
    async getWalletV1TransactionsRaw(requestParameters: GetWalletV1TransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserTransactions200>> {
        if (requestParameters['limit'] == null) {
            throw new runtime.RequiredError(
                'limit',
                'Required parameter "limit" was null or undefined when calling getWalletV1Transactions().'
            );
        }

        if (requestParameters['offset'] == null) {
            throw new runtime.RequiredError(
                'offset',
                'Required parameter "offset" was null or undefined when calling getWalletV1Transactions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['transactionType'] != null) {
            queryParameters['transactionType'] = requestParameters['transactionType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wallet/v1/transactions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserTransactions200FromJSON(jsonValue));
    }

    /**
     * /wallet/v1/transactions
     */
    async getWalletV1Transactions(requestParameters: GetWalletV1TransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserTransactions200> {
        const response = await this.getWalletV1TransactionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создание артиста
     * /artists/v1
     */
    async postArtistsV1Raw(requestParameters: PostArtistsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artist>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPutArtistsRequestToJSON(requestParameters['postPutArtistsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtistFromJSON(jsonValue));
    }

    /**
     * Создание артиста
     * /artists/v1
     */
    async postArtistsV1(requestParameters: PostArtistsV1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artist> {
        const response = await this.postArtistsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /crowdfunding/v1/purchase
     */
    async postCrowdfundingV1PurchaseRaw(requestParameters: PostCrowdfundingV1PurchaseOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/crowdfunding/v1/purchase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostCrowdfundingV1PurchaseRequestToJSON(requestParameters['postCrowdfundingV1PurchaseRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * /crowdfunding/v1/purchase
     */
    async postCrowdfundingV1Purchase(requestParameters: PostCrowdfundingV1PurchaseOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.postCrowdfundingV1PurchaseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /files/v1/common
     */
    async postFilesV1CommonRaw(requestParameters: PostFilesV1CommonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<any>> {
        if (requestParameters['mimeType'] == null) {
            throw new runtime.RequiredError(
                'mimeType',
                'Required parameter "mimeType" was null or undefined when calling postFilesV1Common().'
            );
        }

        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling postFilesV1Common().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['mimeType'] != null) {
            formParams.append('mimeType', requestParameters['mimeType'] as any);
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/files/v1/common`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<any>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * /files/v1/common
     */
    async postFilesV1Common(requestParameters: PostFilesV1CommonRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<any> {
        const response = await this.postFilesV1CommonRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /files/v1/docs
     */
    async postFilesV1DocsRaw(requestParameters: PostFilesV1DocsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostFilesDocs200>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling postFilesV1Docs().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/files/v1/docs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFilesDocs200FromJSON(jsonValue));
    }

    /**
     * /files/v1/docs
     */
    async postFilesV1Docs(requestParameters: PostFilesV1DocsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostFilesDocs200> {
        const response = await this.postFilesV1DocsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Добавление продукта в избранное пользователя
     * /news/v1/userFavorites
     */
    async postNewsV1UserFavoritesRaw(requestParameters: PostNewsV1UserFavoritesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/news/v1/userFavorites`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostDeleteUserFavoriteRequestToJSON(requestParameters['postDeleteUserFavoriteRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Добавление продукта в избранное пользователя
     * /news/v1/userFavorites
     */
    async postNewsV1UserFavorites(requestParameters: PostNewsV1UserFavoritesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postNewsV1UserFavoritesRaw(requestParameters, initOverrides);
    }

    /**
     * Инициация пополнения депозита
     * /payments/v1/deposit
     */
    async postPaymentsV1DepositRaw(requestParameters: PostPaymentsV1DepositOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostPaymentsV1DepositResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/deposit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentsV1DepositRequestToJSON(requestParameters['postPaymentsV1DepositRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostPaymentsV1DepositResponseFromJSON(jsonValue));
    }

    /**
     * Инициация пополнения депозита
     * /payments/v1/deposit
     */
    async postPaymentsV1Deposit(requestParameters: PostPaymentsV1DepositOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostPaymentsV1DepositResponse> {
        const response = await this.postPaymentsV1DepositRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Метод для запроса комиссии для пополнения или вывода средств
     * /payments/v1/fee
     */
    async postPaymentsV1FeeRaw(requestParameters: PostPaymentsV1FeeOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostFeeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/fee`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentsV1FeeRequestToJSON(requestParameters['postPaymentsV1FeeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostFeeResponseFromJSON(jsonValue));
    }

    /**
     * Метод для запроса комиссии для пополнения или вывода средств
     * /payments/v1/fee
     */
    async postPaymentsV1Fee(requestParameters: PostPaymentsV1FeeOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostFeeResponse> {
        const response = await this.postPaymentsV1FeeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Завершения флоу авторизации через paypal для привязки paypal аккаунта
     * /payments/v1/paypal
     */
    async postPaymentsV1PaypalRaw(requestParameters: PostPaymentsV1PaypalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostPaymentsPaypal200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/payments/v1/paypal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentsPaypalRequestToJSON(requestParameters['postPaymentsPaypalRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostPaymentsPaypal200FromJSON(jsonValue));
    }

    /**
     * Завершения флоу авторизации через paypal для привязки paypal аккаунта
     * /payments/v1/paypal
     */
    async postPaymentsV1Paypal(requestParameters: PostPaymentsV1PaypalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostPaymentsPaypal200> {
        const response = await this.postPaymentsV1PaypalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Создание нового продукта лейблом
     * /products/v1
     */
    async postProductsV1Raw(requestParameters: PostProductsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPutProductsRequestToJSON(requestParameters['postPutProductsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * Создание нового продукта лейблом
     * /products/v1
     */
    async postProductsV1(requestParameters: PostProductsV1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.postProductsV1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/avatar
     */
    async postUsersV1AvatarRaw(requestParameters: PostUsersV1AvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostAvatar200>> {
        if (requestParameters['image'] == null) {
            throw new runtime.RequiredError(
                'image',
                'Required parameter "image" was null or undefined when calling postUsersV1Avatar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['image'] != null) {
            formParams.append('image', requestParameters['image'] as any);
        }

        const response = await this.request({
            path: `/users/v1/avatar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostAvatar200FromJSON(jsonValue));
    }

    /**
     * /users/v1/avatar
     */
    async postUsersV1Avatar(requestParameters: PostUsersV1AvatarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostAvatar200> {
        const response = await this.postUsersV1AvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/phone
     */
    async postUsersV1PhoneRaw(requestParameters: PostUsersV1PhoneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostUserPhone200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUserPhoneRequestToJSON(requestParameters['postUserPhoneRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostUserPhone200FromJSON(jsonValue));
    }

    /**
     * /users/v1/phone
     */
    async postUsersV1Phone(requestParameters: PostUsersV1PhoneRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostUserPhone200> {
        const response = await this.postUsersV1PhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/phone/validate
     */
    async postUsersV1PhoneValidateRaw(requestParameters: PostUsersV1PhoneValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostUserPhoneValidate200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/phone/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUserPhoneValidateRequestToJSON(requestParameters['postUserPhoneValidateRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostUserPhoneValidate200FromJSON(jsonValue));
    }

    /**
     * /users/v1/phone/validate
     */
    async postUsersV1PhoneValidate(requestParameters: PostUsersV1PhoneValidateRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostUserPhoneValidate200> {
        const response = await this.postUsersV1PhoneValidateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /wallet/v1/withdrawal
     */
    async postWalletV1WithdrawalRaw(requestParameters: PostWalletV1WithdrawalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostWithdrawalResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wallet/v1/withdrawal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostWithdrawalRequestToJSON(requestParameters['postWithdrawalRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostWithdrawalResponseFromJSON(jsonValue));
    }

    /**
     * /wallet/v1/withdrawal
     */
    async postWalletV1Withdrawal(requestParameters: PostWalletV1WithdrawalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostWithdrawalResponse> {
        const response = await this.postWalletV1WithdrawalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * апдейт артиста
     * /artists/v1/{artistId}
     */
    async putArtistsV1ArtistIdRaw(requestParameters: PutArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Artist>> {
        if (requestParameters['artistId'] == null) {
            throw new runtime.RequiredError(
                'artistId',
                'Required parameter "artistId" was null or undefined when calling putArtistsV1ArtistId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/artists/v1/{artistId}`.replace(`{${"artistId"}}`, encodeURIComponent(String(requestParameters['artistId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostPutArtistsRequestToJSON(requestParameters['postPutArtistsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArtistFromJSON(jsonValue));
    }

    /**
     * апдейт артиста
     * /artists/v1/{artistId}
     */
    async putArtistsV1ArtistId(requestParameters: PutArtistsV1ArtistIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Artist> {
        const response = await this.putArtistsV1ArtistIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * редактирование продукта (для лейбла)
     * /products/v1/{productId}
     */
    async putProductsV1ProductIdRaw(requestParameters: PutProductsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Product>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling putProductsV1ProductId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/products/v1/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostPutProductsRequestToJSON(requestParameters['postPutProductsRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductFromJSON(jsonValue));
    }

    /**
     * редактирование продукта (для лейбла)
     * /products/v1/{productId}
     */
    async putProductsV1ProductId(requestParameters: PutProductsV1ProductIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Product> {
        const response = await this.putProductsV1ProductIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update profile
     * /users/v1/profile
     */
    async putUsersV1ProfileRaw(requestParameters: PutUsersV1ProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PutUserProfileResponse200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/profile`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProfileRequestToJSON(requestParameters['updateProfileRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PutUserProfileResponse200FromJSON(jsonValue));
    }

    /**
     * Update profile
     * /users/v1/profile
     */
    async putUsersV1Profile(requestParameters: PutUsersV1ProfileRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PutUserProfileResponse200> {
        const response = await this.putUsersV1ProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * /users/v1/profile/privacy
     */
    async putUsersV1ProfilePrivacyRaw(requestParameters: PutUsersV1ProfilePrivacyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserProfile200>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/v1/profile/privacy`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutUsersV1ProfilePrivacyRequestToJSON(requestParameters['putUsersV1ProfilePrivacyRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserProfile200FromJSON(jsonValue));
    }

    /**
     * /users/v1/profile/privacy
     */
    async putUsersV1ProfilePrivacy(requestParameters: PutUsersV1ProfilePrivacyOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserProfile200> {
        const response = await this.putUsersV1ProfilePrivacyRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetWalletV1TransactionsTransactionTypeEnum = {
    withdrawal: 'withdrawal',
    refund: 'refund',
    deposit: 'deposit',
    royalty_payment: 'royalty_payment',
    purchase: 'purchase'
} as const;
export type GetWalletV1TransactionsTransactionTypeEnum = typeof GetWalletV1TransactionsTransactionTypeEnum[keyof typeof GetWalletV1TransactionsTransactionTypeEnum];
/**
 * @export
 */
export const PostFilesV1CommonMimeTypeEnum = {
    audio_mpeg: 'audio/mpeg',
    video_mp4: 'video/mp4',
    image_jpeg: 'image/jpeg',
    image_png: 'image/png'
} as const;
export type PostFilesV1CommonMimeTypeEnum = typeof PostFilesV1CommonMimeTypeEnum[keyof typeof PostFilesV1CommonMimeTypeEnum];
