/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Gender } from './Gender';
import {
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';
import type { KYCStatus } from './KYCStatus';
import {
    KYCStatusFromJSON,
    KYCStatusFromJSONTyped,
    KYCStatusToJSON,
} from './KYCStatus';
import type { RolesEnum } from './RolesEnum';
import {
    RolesEnumFromJSON,
    RolesEnumFromJSONTyped,
    RolesEnumToJSON,
} from './RolesEnum';

/**
 * 
 * @export
 * @interface PutUserProfileResponse200
 */
export interface PutUserProfileResponse200 {
    /**
     * 
     * @type {string}
     * @memberof PutUserProfileResponse200
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserProfileResponse200
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserProfileResponse200
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserProfileResponse200
     */
    nickname: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserProfileResponse200
     */
    avatarUrl: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutUserProfileResponse200
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutUserProfileResponse200
     */
    phone: string | null;
    /**
     * 
     * @type {Gender}
     * @memberof PutUserProfileResponse200
     */
    gender: Gender;
    /**
     * 
     * @type {Date}
     * @memberof PutUserProfileResponse200
     */
    birthDate: Date;
    /**
     * 
     * @type {string}
     * @memberof PutUserProfileResponse200
     */
    country: string;
    /**
     * 
     * @type {Date}
     * @memberof PutUserProfileResponse200
     */
    registrationDate: Date;
    /**
     * Роли пользователя
     * @type {Array<RolesEnum>}
     * @memberof PutUserProfileResponse200
     */
    roles: Array<RolesEnum>;
    /**
     * статус проверки KYC
     * @type {KYCStatus}
     * @memberof PutUserProfileResponse200
     */
    kycStatus: KYCStatus;
    /**
     * был ли профиль пользователя заполнен полностью
     * @type {boolean}
     * @memberof PutUserProfileResponse200
     */
    isFilled: boolean;
    /**
     * является ли профиль приватным (публичный по умолчанию)
     * @type {boolean}
     * @memberof PutUserProfileResponse200
     */
    isPrivate: boolean;
}

/**
 * Check if a given object implements the PutUserProfileResponse200 interface.
 */
export function instanceOfPutUserProfileResponse200(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('nickname' in value)) return false;
    if (!('avatarUrl' in value)) return false;
    if (!('email' in value)) return false;
    if (!('phone' in value)) return false;
    if (!('gender' in value)) return false;
    if (!('birthDate' in value)) return false;
    if (!('country' in value)) return false;
    if (!('registrationDate' in value)) return false;
    if (!('roles' in value)) return false;
    if (!('kycStatus' in value)) return false;
    if (!('isFilled' in value)) return false;
    if (!('isPrivate' in value)) return false;
    return true;
}

export function PutUserProfileResponse200FromJSON(json: any): PutUserProfileResponse200 {
    return PutUserProfileResponse200FromJSONTyped(json, false);
}

export function PutUserProfileResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): PutUserProfileResponse200 {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'nickname': json['nickname'],
        'avatarUrl': json['avatarUrl'],
        'email': json['email'],
        'phone': json['phone'],
        'gender': GenderFromJSON(json['gender']),
        'birthDate': (new Date(json['birthDate'])),
        'country': json['country'],
        'registrationDate': (new Date(json['registrationDate'])),
        'roles': ((json['roles'] as Array<any>).map(RolesEnumFromJSON)),
        'kycStatus': KYCStatusFromJSON(json['kycStatus']),
        'isFilled': json['isFilled'],
        'isPrivate': json['isPrivate'],
    };
}

export function PutUserProfileResponse200ToJSON(value?: PutUserProfileResponse200 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'nickname': value['nickname'],
        'avatarUrl': value['avatarUrl'],
        'email': value['email'],
        'phone': value['phone'],
        'gender': GenderToJSON(value['gender']),
        'birthDate': ((value['birthDate']).toISOString().substring(0,10)),
        'country': value['country'],
        'registrationDate': ((value['registrationDate']).toISOString()),
        'roles': ((value['roles'] as Array<any>).map(RolesEnumToJSON)),
        'kycStatus': KYCStatusToJSON(value['kycStatus']),
        'isFilled': value['isFilled'],
        'isPrivate': value['isPrivate'],
    };
}

