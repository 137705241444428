/* tslint:disable */
/* eslint-disable */
export * from './Artist';
export * from './ArtistPlatforms';
export * from './ArtistSocialMedia';
export * from './CampaignRevenueEntry';
export * from './CampaignStats';
export * from './CampaignStatsEntry';
export * from './CrowdFundingCampaign';
export * from './Currency';
export * from './DeleteArtistsV1ArtistIdError';
export * from './DeleteNewsV1UserFavoritesError';
export * from './DeletePaymentsV1PaypalError';
export * from './DeleteProductsV1Error';
export * from './Docs';
export * from './ErrorCode';
export * from './FullNews';
export * from './Gender';
export * from './GetArtistsLableId200';
export * from './GetArtistsV1ArtistIdError';
export * from './GetArtistsV1Error';
export * from './GetBalanceResponse';
export * from './GetFavoritesNews200';
export * from './GetFeatured200';
export * from './GetFilesV1DocsError';
export * from './GetInvestors200';
export * from './GetInvestorsV1ProductIdError';
export * from './GetKYCV1Status200';
export * from './GetKYCV1SumSubToken200';
export * from './GetKycV1StatusError';
export * from './GetKycV1SumsubTokenError';
export * from './GetNews200';
export * from './GetNewsV1Error';
export * from './GetNewsV1FavoritesError';
export * from './GetNewsV1NewsIdError';
export * from './GetNewsV1ProductProductIdError';
export * from './GetNewsV1UserFavoritesUserIdError';
export * from './GetNewsV1UserNewsUserIdError';
export * from './GetPaymentsPaypal200';
export * from './GetPaymentsV1PaypalError';
export * from './GetPortfolioV1UserPortfoliosUserIdError';
export * from './GetProductNews200';
export * from './GetProducts200';
export * from './GetProductsV1AllError';
export * from './GetProductsV1Error';
export * from './GetProductsV1FeaturedError';
export * from './GetProductsV1ProductIdError';
export * from './GetUserFavorites200';
export * from './GetUserNicknameValidate200';
export * from './GetUserPortfolio200';
export * from './GetUserProfile200';
export * from './GetUserTransactions200';
export * from './GetUsersV1ProfileError';
export * from './GetUsersV1UserIdInfoError';
export * from './GetWalletV1BalanceError';
export * from './GetWalletV1TransactionsError';
export * from './InternalServerError500';
export * from './Investor';
export * from './KYCStatus';
export * from './MimeTypeEnum';
export * from './ModelError';
export * from './ModelFile';
export * from './News';
export * from './NotFoundError404';
export * from './PostArtistsV1Error';
export * from './PostAvatar200';
export * from './PostCrowdfundingV1PurchaseError';
export * from './PostCrowdfundingV1PurchaseRequest';
export * from './PostDeleteUserFavoriteRequest';
export * from './PostFeeResponse';
export * from './PostFilesDocs200';
export * from './PostFilesV1CommonError';
export * from './PostFilesV1DocsError';
export * from './PostNewsV1UserFavoritesError';
export * from './PostPaymentsPaypal200';
export * from './PostPaymentsPaypalRequest';
export * from './PostPaymentsV1DepositError';
export * from './PostPaymentsV1DepositRequest';
export * from './PostPaymentsV1DepositResponse';
export * from './PostPaymentsV1FeeError';
export * from './PostPaymentsV1FeeRequest';
export * from './PostPaymentsV1PaypalError';
export * from './PostProductsV1Error';
export * from './PostPutArtistsRequest';
export * from './PostPutProductsRequest';
export * from './PostUserPhone200';
export * from './PostUserPhoneRequest';
export * from './PostUserPhoneValidate200';
export * from './PostUserPhoneValidateRequest';
export * from './PostUsersV1AvatarError';
export * from './PostUsersV1PhoneError';
export * from './PostUsersV1PhoneValidateError';
export * from './PostWalletV1WithdrawalError';
export * from './PostWithdrawalRequest';
export * from './PostWithdrawalResponse';
export * from './Product';
export * from './ProductTypeEnum';
export * from './PutArtistsV1ArtistIdError';
export * from './PutProductsV1ProductIdError';
export * from './PutUserProfileResponse200';
export * from './PutUsersV1ProfileError';
export * from './PutUsersV1ProfilePrivacyError';
export * from './PutUsersV1ProfilePrivacyRequest';
export * from './RolesEnum';
export * from './Snippet';
export * from './SocialMetrics';
export * from './TransactionInfoProduct';
export * from './Transactions';
export * from './UpdateProfileRequest';
export * from './UserDef';
export * from './UserInfo';
export * from './UserInfoStatistics';
