/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TransactionInfoProduct } from './TransactionInfoProduct';
import {
    TransactionInfoProductFromJSON,
    TransactionInfoProductFromJSONTyped,
    TransactionInfoProductToJSON,
} from './TransactionInfoProduct';

/**
 * Модель транзакции
 * @export
 * @interface Transactions
 */
export interface Transactions {
    /**
     * 
     * @type {string}
     * @memberof Transactions
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Transactions
     */
    dateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof Transactions
     */
    type: TransactionsTypeEnum;
    /**
     * Статус транзакции
     * @type {string}
     * @memberof Transactions
     */
    withdrawalStatus?: TransactionsWithdrawalStatusEnum;
    /**
     * в минимальных базовых единицах
     * @type {number}
     * @memberof Transactions
     */
    amount: number;
    /**
     * 
     * @type {TransactionInfoProduct}
     * @memberof Transactions
     */
    product: TransactionInfoProduct | null;
}


/**
 * @export
 */
export const TransactionsTypeEnum = {
    deposit: 'deposit',
    royalty_payment: 'royalty_payment',
    purchase: 'purchase',
    refund: 'refund',
    withdrawal: 'withdrawal',
    reverted_withdrawal: 'reverted_withdrawal',
    admin_action: 'admin_action'
} as const;
export type TransactionsTypeEnum = typeof TransactionsTypeEnum[keyof typeof TransactionsTypeEnum];

/**
 * @export
 */
export const TransactionsWithdrawalStatusEnum = {
    requested: 'requested',
    approved: 'approved',
    denied: 'denied',
    enqueued: 'enqueued',
    succeeded: 'succeeded',
    failed: 'failed',
    reverted: 'reverted'
} as const;
export type TransactionsWithdrawalStatusEnum = typeof TransactionsWithdrawalStatusEnum[keyof typeof TransactionsWithdrawalStatusEnum];


/**
 * Check if a given object implements the Transactions interface.
 */
export function instanceOfTransactions(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('dateTime' in value)) return false;
    if (!('type' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('product' in value)) return false;
    return true;
}

export function TransactionsFromJSON(json: any): Transactions {
    return TransactionsFromJSONTyped(json, false);
}

export function TransactionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Transactions {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'dateTime': (new Date(json['dateTime'])),
        'type': json['type'],
        'withdrawalStatus': json['withdrawalStatus'] == null ? undefined : json['withdrawalStatus'],
        'amount': json['amount'],
        'product': TransactionInfoProductFromJSON(json['product']),
    };
}

export function TransactionsToJSON(value?: Transactions | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'dateTime': ((value['dateTime']).toISOString()),
        'type': value['type'],
        'withdrawalStatus': value['withdrawalStatus'],
        'amount': value['amount'],
        'product': TransactionInfoProductToJSON(value['product']),
    };
}

