/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Gender } from './Gender';
import {
    GenderFromJSON,
    GenderFromJSONTyped,
    GenderToJSON,
} from './Gender';

/**
 * 
 * @export
 * @interface UpdateProfileRequest
 */
export interface UpdateProfileRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequest
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequest
     */
    surname: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateProfileRequest
     */
    birthDate: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequest
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProfileRequest
     */
    nickname: string;
    /**
     * 
     * @type {Gender}
     * @memberof UpdateProfileRequest
     */
    gender: Gender;
}

/**
 * Check if a given object implements the UpdateProfileRequest interface.
 */
export function instanceOfUpdateProfileRequest(value: object): boolean {
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('birthDate' in value)) return false;
    if (!('country' in value)) return false;
    if (!('nickname' in value)) return false;
    if (!('gender' in value)) return false;
    return true;
}

export function UpdateProfileRequestFromJSON(json: any): UpdateProfileRequest {
    return UpdateProfileRequestFromJSONTyped(json, false);
}

export function UpdateProfileRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateProfileRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'surname': json['surname'],
        'birthDate': (json['birthDate'] == null ? null : new Date(json['birthDate'])),
        'country': json['country'],
        'nickname': json['nickname'],
        'gender': GenderFromJSON(json['gender']),
    };
}

export function UpdateProfileRequestToJSON(value?: UpdateProfileRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'surname': value['surname'],
        'birthDate': (value['birthDate'] == null ? null : (value['birthDate'] as any).toISOString().substring(0,10)),
        'country': value['country'],
        'nickname': value['nickname'],
        'gender': GenderToJSON(value['gender']),
    };
}

