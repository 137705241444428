import {
  Configuration,
  DefaultApi,
  ResponseContext,
  ModelError,
} from './codegen/demuzo';
import {
  isBusinessApiError,
  isAuthApiError,
  makeApiClientError,
  isServerApiError,
} from '@/shared/api/utils';
import { addError, GlobalError } from '@/entities/global-error/model';
import { getRuntimeType } from '@/shared/utils/get-runtime-type';
import { ApiClientErrorPayload } from '@/shared/api/types';

const addGlobalError = async (error: GlobalError) => {
  const runtime = getRuntimeType();
  if (runtime === 'client') {
    addError({ error, id: Date.now() });
  }
};

const configuration = new Configuration({
  middleware: [
    {
      async post(context: ResponseContext): Promise<Response | void> {
        const response = context.response;

        if (response.status >= 200 && response.status < 300) {
          return response;
        }

        const json: ModelError | null = await response
          .clone()
          .json()
          .catch(() => null);

        const text = await response
          .clone()
          .text()
          .catch(() => null);

        const payload: ApiClientErrorPayload = {
          url: response.url,
          status: response.status,
          statusText: response.statusText,
          serverPayload: json,
          body: text,
        };

        if (isAuthApiError(json)) {
          await addGlobalError({
            code: 'AUTHORIZATION_ERROR',
            serverCode: json?.code || null,
          });
          throw makeApiClientError(
            'NON_BUSINESS_ERROR',
            'Unauthorized',
            payload,
          );
        }

        if (!isServerApiError(json)) {
          await addGlobalError({
            code: 'BLOCK_USER_ACTIONS_ERROR',
            serverCode: null,
            title: response.status.toString(),
            message: response.statusText,
            helpText: 'Try again later',
          });
          throw makeApiClientError(
            'NON_BUSINESS_ERROR',
            'Unknown server payload',
            payload,
          );
        }

        if (isBusinessApiError(json)) {
          throw json;
        }

        await addGlobalError({
          code: 'NOTIFICATION_ERROR',
          serverCode: json.code,
          message: json.message,
        });
        throw makeApiClientError('NON_BUSINESS_ERROR', 'Global Error', payload);
      },
    },
  ],
  basePath: `${process.env.NEXT_PUBLIC_BASE_URL}/api/gateway`,
});

export const demuzoApi = new DefaultApi(configuration);
