/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Artist } from './Artist';
import {
    ArtistFromJSON,
    ArtistFromJSONTyped,
    ArtistToJSON,
} from './Artist';
import type { UserInfoStatistics } from './UserInfoStatistics';
import {
    UserInfoStatisticsFromJSON,
    UserInfoStatisticsFromJSONTyped,
    UserInfoStatisticsToJSON,
} from './UserInfoStatistics';

/**
 * Модель публичного/приватного профиля пользователя (информация о пользователе)
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof UserInfo
     */
    registerDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    nickname: string;
    /**
     * имя
     * @type {string}
     * @memberof UserInfo
     */
    firstName: string;
    /**
     * фамилия
     * @type {string}
     * @memberof UserInfo
     */
    surname: string;
    /**
     * null в случае отсутствия аватарки
     * @type {string}
     * @memberof UserInfo
     */
    avatarUrl: string | null;
    /**
     * 
     * @type {UserInfoStatistics}
     * @memberof UserInfo
     */
    statistics: UserInfoStatistics;
    /**
     * Заполняется если есть профиль артиста связанный с пользователем
     * @type {Array<Artist>}
     * @memberof UserInfo
     */
    artistProfile: Array<Artist>;
}

/**
 * Check if a given object implements the UserInfo interface.
 */
export function instanceOfUserInfo(value: object): boolean {
    if (!('userId' in value)) return false;
    if (!('registerDateTime' in value)) return false;
    if (!('nickname' in value)) return false;
    if (!('firstName' in value)) return false;
    if (!('surname' in value)) return false;
    if (!('avatarUrl' in value)) return false;
    if (!('statistics' in value)) return false;
    if (!('artistProfile' in value)) return false;
    return true;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'registerDateTime': (new Date(json['registerDateTime'])),
        'nickname': json['nickname'],
        'firstName': json['firstName'],
        'surname': json['surname'],
        'avatarUrl': json['avatarUrl'],
        'statistics': UserInfoStatisticsFromJSON(json['statistics']),
        'artistProfile': ((json['artistProfile'] as Array<any>).map(ArtistFromJSON)),
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'registerDateTime': ((value['registerDateTime']).toISOString()),
        'nickname': value['nickname'],
        'firstName': value['firstName'],
        'surname': value['surname'],
        'avatarUrl': value['avatarUrl'],
        'statistics': UserInfoStatisticsToJSON(value['statistics']),
        'artistProfile': ((value['artistProfile'] as Array<any>).map(ArtistToJSON)),
    };
}

