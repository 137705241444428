/* tslint:disable */
/* eslint-disable */
/**
 * demuzo-openapi
 * specification description
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArtistPlatforms
 */
export interface ArtistPlatforms {
    /**
     * Название платформы
     * @type {string}
     * @memberof ArtistPlatforms
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ArtistPlatforms
     */
    type: ArtistPlatformsTypeEnum;
    /**
     * Ссылка на платформу
     * @type {string}
     * @memberof ArtistPlatforms
     */
    url: string;
    /**
     * Количество прослушиваний на платформе
     * @type {string}
     * @memberof ArtistPlatforms
     */
    streams: string;
}


/**
 * @export
 */
export const ArtistPlatformsTypeEnum = {
    spotify: 'spotify',
    youtube: 'youtube',
    apple_music: 'apple_music',
    other: 'other',
    soundCloud: 'soundCloud'
} as const;
export type ArtistPlatformsTypeEnum = typeof ArtistPlatformsTypeEnum[keyof typeof ArtistPlatformsTypeEnum];


/**
 * Check if a given object implements the ArtistPlatforms interface.
 */
export function instanceOfArtistPlatforms(value: object): boolean {
    if (!('title' in value)) return false;
    if (!('type' in value)) return false;
    if (!('url' in value)) return false;
    if (!('streams' in value)) return false;
    return true;
}

export function ArtistPlatformsFromJSON(json: any): ArtistPlatforms {
    return ArtistPlatformsFromJSONTyped(json, false);
}

export function ArtistPlatformsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArtistPlatforms {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'type': json['type'],
        'url': json['url'],
        'streams': json['streams'],
    };
}

export function ArtistPlatformsToJSON(value?: ArtistPlatforms | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'type': value['type'],
        'url': value['url'],
        'streams': value['streams'],
    };
}

